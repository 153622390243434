import { NgModule } from '@angular/core';
import { environment } from '@et/environment';
import {
  v2ProfileApiModule,
  Configuration,
  ConfigurationParameters,
} from '@escrowtab/v2profile-api-client';
import {
  docprepApiModule,
  Configuration as DocPrepConfiguration,
  ConfigurationParameters as DocPrepConfigurationParameters,
} from '@escrowtab/docprep-api-client';

function profileConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.profilev2.domain,
  };
  return new Configuration(params);
}

function docPrepConfigFactory(): DocPrepConfiguration {
  const params: DocPrepConfigurationParameters = {
    basePath: environment.docPrepService.domain,
  };
  return new DocPrepConfiguration(params);
}

@NgModule({
  imports: [
    v2ProfileApiModule.forRoot(profileConfigFactory),
    docprepApiModule.forRoot(docPrepConfigFactory),
  ],
  exports: [v2ProfileApiModule],
})
export class AppApiModule {}
