import {
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { Contact } from '@et/typings';

@Component({
  selector: 'interface-contacts-drop-down-item',
  standalone: true,
  template: `
    {{ contact.name }} <span class="opacity-70">({{ contact.email }})</span>
  `,
  styles: [
    `
      :host {
        @apply block px-4 py-2 cursor-pointer hover:bg-base-100;
        &.active {
          @apply bg-base-100;
        }
      }
    `,
  ],
})
export class ContactsDropDownItemComponent implements Highlightable {
  private elementRef = inject(ElementRef);

  @Input({ required: true }) contact!: Contact;

  @HostBinding('class.active')
  active = false;

  /**
   * Sets the active styles for the dropdown item.
   *
   * This method sets the `active` property to true and scrolls the item into view.
   */
  setActiveStyles(): void {
    this.active = true;
    this.scrollIntoView();
  }

  /**
   * Sets the inactive styles for the dropdown item.
   *
   * This method sets the `active` property to false.
   */
  setInactiveStyles(): void {
    this.active = false;
  }

  /**
   * Gets the label for the dropdown item.
   *
   * This method returns the name of the contact associated with the dropdown item.
   */
  getLabel(): string {
    return this.contact.name;
  }

  /**
   * Scrolls the dropdown item into view.
   *
   * This method scrolls the dropdown item into view, centering it within the scrollable container.
   */
  private scrollIntoView() {
    this.elementRef.nativeElement.scrollIntoView({ block: 'center' });
  }
}
