import {
  Directive,
  HostListener,
  Self,
  OnDestroy,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[interfaceCurrencyFormatter]',
})
export class CurrencyFormatterDirective
  implements OnDestroy, AfterViewInit, OnInit
{
  private formatter!: Intl.NumberFormat;
  private _onDestroy = new Subject<void>();

  constructor(@Self() private ngControl: NgControl) {}

  ngOnInit(): void {
    this.formatter = new Intl.NumberFormat(
      Intl.DateTimeFormat().resolvedOptions().locale,
      {
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'USD',
      },
    );
  }

  ngAfterViewInit() {
    if (!this.ngControl.control) {
      return;
    }
    if (this.ngControl.value) {
      const v = this.formatPrice(this.ngControl.value);
      this.setValue(v);
    }
    this.ngControl.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(this.updateValue.bind(this));
  }

  /**
   * Updates field value with currency formatted and passes to setValue function
   *
   * @memberOf CurrencyFormatterDirective
   */
  updateValue(value: string) {
    const inputVal = value || '';
    this.setValue(
      inputVal
        ? this.validateDecimalValue(inputVal.replace(/[^0-9.]/g, ''))
        : '',
    );
  }

  /**
   * On focus removes currency format from field value and passes to setValue function
   *
   * @memberOf CurrencyFormatterDirective
   */
  @HostListener('focus') onFocus() {
    this.setValue(this.unformatValue(this.ngControl.value));
  }

  /**
   * On blur adds currency format to field value and passes to setValue function
   *
   * @memberOf CurrencyFormatterDirective
   */
  @HostListener('blur') onBlur() {
    const value = this.ngControl.value || '';
    !!value && this.setValue(this.formatPrice(value));
  }

  /**
   * Adds currency format value
   *
   * @memberOf CurrencyFormatterDirective
   */
  formatPrice(v: number) {
    return this.formatter.format(v);
  }

  /**
   * Removes currency format from value
   *
   * @memberOf CurrencyFormatterDirective
   */
  unformatValue(v: string) {
    if (!v) {
      return v;
    }
    return v.replace(/[^0-9.]/g, '');
  }

  /**
   * Validates value to make sure it is a number
   *
   * @memberOf CurrencyFormatterDirective
   */
  validateDecimalValue(v: string) {
    // Check to see if the value is a valid number or not
    if (Number.isNaN(Number(v))) {
      // strip out last char as this would have made the value invalid
      const strippedValue = v.slice(0, v.length - 1);

      // if value is still invalid, then this would be copy/paste scenario
      // and in such case we simply set the value to empty
      return Number.isNaN(Number(strippedValue)) ? '' : strippedValue;
    }
    return v;
  }

  /**
   * Updates input control with provided value
   *
   * @memberOf CurrencyFormatterDirective
   */
  setValue(v: string) {
    if (!this.ngControl.control) {
      return;
    }
    this.ngControl.control.setValue(v, { emitEvent: false });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
