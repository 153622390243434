import {
  Directive,
  Self,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';

@Directive({
  selector: '[interfaceInputDigits]',
})
export class InputDigitsDirective implements OnInit, OnDestroy {
  sub!: Subscription;

  @Output() valueChanges = new EventEmitter<string>();

  constructor(@Self() private ngControl: NgControl) {}

  ngOnInit(): void {
    this.sub = this.ngControl.valueChanges
      ?.pipe(
        distinctUntilChanged(),
        filter((v) => !!v),
      )
      .subscribe((value) => {
        const digits = value.replace(/\D/g, '');
        this.ngControl.control?.setValue(digits);
        if (digits) {
          this.valueChanges.emit(digits);
        }
      }) as Subscription;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
