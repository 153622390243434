import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[interfaceStickToTop]',
  standalone: true,
})
export class StickToTopDirective implements AfterViewInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  private originalOffsetTop: number | undefined;
  private originalLeft: number | undefined;
  private originalWindowWidth: number | undefined;
  private isStuck: boolean = false;

  ngAfterViewInit(): void {
    requestAnimationFrame(() => {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const windowScroll = document.documentElement.scrollTop;
      this.originalOffsetTop = rect.top + windowScroll;
      this.originalWindowWidth = window.innerWidth;
    });
  }

  /**
   * Handles the window scroll event.
   *
   * This method checks the current scroll position and determines whether to stick
   * or unstick the element based on its original offset top.
   *
   * @returns {void}
   */
  @HostListener('window:scroll')
  onWindowScroll(): void {
    if (!this.originalLeft) {
      this.originalLeft = this.el.nativeElement.getBoundingClientRect().left;
    }

    if (!this.originalOffsetTop) return;

    const scrollPosition = document.documentElement.scrollTop;

    if (scrollPosition >= this.originalOffsetTop && !this.isStuck) {
      this.stickElement();
    } else if (scrollPosition < this.originalOffsetTop && this.isStuck) {
      this.unstickElement();
    }
  }

  /**
   * Handles the window resize event.
   *
   * This method adjusts the left position of the element based on the change in window width.
   * If the element is stuck, it recalculates the left position and updates the element's style.
   * If the element is not stuck, it updates the original left position.
   *
   * @returns {void}
   */
  @HostListener('window:resize')
  onWindowResize(): void {
    if (!this.isStuck) {
      this.originalLeft = this.el.nativeElement.getBoundingClientRect().left;
      this.originalWindowWidth = window.innerWidth;
    }

    if (!this.originalWindowWidth || !this.originalLeft) return;
    const deltaX = window.innerWidth - this.originalWindowWidth;

    if (this.isStuck) {
      const updatedLeft = this.originalLeft + deltaX / 2;
      this.renderer.setStyle(this.el.nativeElement, 'left', `${updatedLeft}px`);
    }
  }

  /**
   * Sticks the element to the top of the window.
   *
   * This method sets the element's position to fixed and adjusts its top and left properties
   * to stick it to the top of the window.
   *
   * @returns {void}
   */
  private stickElement(): void {
    const left = this.el.nativeElement.getBoundingClientRect().left;
    this.isStuck = true;
    this.renderer.setStyle(this.el.nativeElement, 'position', 'fixed');
    this.renderer.setStyle(this.el.nativeElement, 'top', '0');
    this.renderer.setStyle(this.el.nativeElement, 'left', `${left}px`);
    this.originalLeft = left;
  }

  /**
   * Unsticks the element from the top of the window.
   *
   * This method removes the fixed position and top and left properties from the element to
   * unstick it from the top of the window.
   *
   * @returns {void}
   */
  private unstickElement(): void {
    this.isStuck = false;
    this.renderer.removeStyle(this.el.nativeElement, 'position');
    this.renderer.removeStyle(this.el.nativeElement, 'top');
    this.renderer.removeStyle(this.el.nativeElement, 'left');
    this.originalWindowWidth = window.innerWidth;
    this.originalLeft = this.el.nativeElement.getBoundingClientRect().left;
  }
}
