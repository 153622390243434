import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Contact } from '@et/typings';
import { ContactsDropDownItemComponent } from '../contacts-drop-down-item/contacts-drop-down-item.component';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';

@Component({
  selector: 'interface-contacts-drop-down',
  standalone: true,
  imports: [ContactsDropDownItemComponent],
  templateUrl: './contacts-drop-down.component.html',
  styleUrl: './contacts-drop-down.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsDropDownComponent implements AfterViewInit, OnChanges {
  private cdr = inject(ChangeDetectorRef);

  @Input() contacts: Contact[] = [];
  @Output() contactSelected = new EventEmitter<Contact>();

  @ViewChildren(ContactsDropDownItemComponent)
  selectOptions!: QueryList<ContactsDropDownItemComponent>;

  keyManager!: ActiveDescendantKeyManager<ContactsDropDownItemComponent>;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.handleKeyDown(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.keyManager && changes['contacts']) {
      Promise.resolve().then(() => {
        this.keyManager?.setActiveItem(0);
        this.cdr.markForCheck();
      });
    }
  }

  ngAfterViewInit(): void {
    this.initKeyManager();
  }

  /**
   * Emits the selected contact.
   *
   * @param {Contact} contact - The selected contact.
   */
  onSelect(contact: Contact) {
    this.contactSelected.emit(contact);
  }

  /**
   * Handles keyboard navigation within the dropdown.
   *
   * This method listens for keyboard events and navigates through the dropdown items using the arrow keys.
   * When the Enter key is pressed, it selects the currently active item.
   *
   * @param {KeyboardEvent} event - The keyboard event.
   */
  private handleKeyDown(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'ArrowDown' || key === 'ArrowUp') {
      this.keyManager.onKeydown(event);
    } else if (key === 'Enter') {
      const contact = this.keyManager.activeItem?.contact;
      if (contact) {
        this.onSelect(contact);
        event.preventDefault();
      }
    }
  }

  /**
   * Initializes the key manager for keyboard navigation.
   *
   * This method sets up the `ActiveDescendantKeyManager`
   * to manage keyboard navigation within the dropdown.
   */
  private initKeyManager() {
    this.keyManager =
      new ActiveDescendantKeyManager<ContactsDropDownItemComponent>(
        this.selectOptions,
      )
        .withTypeAhead()
        .withVerticalOrientation()
        .withHorizontalOrientation('ltr')
        .withHomeAndEnd()
        .withWrap();

    this.keyManager.setActiveItem(0);
    this.cdr.detectChanges();
  }
}
