import {
  ComponentRef,
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ContactsDropDownComponent } from './contacts-drop-down/contacts-drop-down.component';
import { ContactsService } from '@et/api';
import {
  debounceTime,
  filter,
  fromEvent,
  map,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Contact } from '@et/typings';
import { ComponentPortal } from '@angular/cdk/portal';

@Directive({
  selector: '[interfaceContactsAutoComplete]',
  standalone: true,
})
export class ContactsAutoCompleteDirective implements OnInit {
  private el = inject(ElementRef, { self: true });
  private viewContainerRef = inject(ViewContainerRef, { self: true });
  private overlay = inject(Overlay);
  private contactsService = inject(ContactsService);
  private destroyRef = inject(DestroyRef);

  @Input({ required: true }) userId: string | null | undefined;
  @Output() contactSelected = new EventEmitter<Contact>();

  private componentRef!: ComponentRef<ContactsDropDownComponent>;
  private overlayRef!: OverlayRef;

  ngOnInit(): void {
    this.listenForValueChanges();
  }

  /**
   * Listens for input value changes and fetches contacts.
   *
   * This method sets up an observable to listen for input events on the element. It debounces the input by
   * 400 milliseconds, trims the input value, and filters out values with a length of 2 or less.
   * If the input value is empty and an overlay is present, it detaches the overlay.
   * Otherwise, it fetches contacts based on the input value and shows the overlay with them.
   *
   * @returns {void}
   */
  private listenForValueChanges(): void {
    fromEvent(this.el.nativeElement, 'input')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        debounceTime(400),
        map((event: any) => event.target.value.trim()),
        tap((value) => {
          if (!value && this.overlayRef) this.overlayRef.detach();
        }),
        filter((value) => value.length > 2),
        filter(Boolean),
        switchMap((value) => this.getContacts(value)),
      )
      .subscribe((values) => {
        if (values.length) {
          this.showOverlay(values);
        } else if (this.overlayRef) {
          this.overlayRef.detach();
        }
      });
  }

  /**
   * Fetches contacts based on the input value.
   *
   * This method requires user ID and uses it to fetch contacts from the `contactsService`
   * based on the provided input value. If the user ID is not available, it throws an error.
   *
   * @param {string} value - The input value to search contacts by.
   * @returns {Observable<Contact[]>} An observable that emits the list of contacts matching the input value.
   * @throws {Error} If the user ID is not available.
   */
  private getContacts(value: string): Observable<Contact[]> {
    const userId = this.userId;
    if (!userId)
      throw new Error(
        'userId is required for contacts auto complete directive',
      );
    return this.contactsService.getContactsByNameOrEmail(value, userId);
  }

  /**
   * Displays the overlay with the provided contact values.
   *
   * This method ensures that the overlay is created and attached.
   * It then sets the provided contact values to the dropdown component.
   *
   * @param {Contact[]} values - The list of contacts to display in the overlay.
   * @returns {void}
   */
  private showOverlay(values: Contact[]): void {
    // Make sure the overlay is created
    if (!this.overlayRef) {
      this.createOverlay();
    }
    // Make sure the overlay is attached
    if (!this.overlayRef.hasAttached()) {
      this.attachDropdownToOverlay();
    }
    // Set the contacts to the dropdown component
    this.componentRef.setInput('contacts', values);
  }

  /**
   * Creates the overlay for displaying contact suggestions.
   */
  private createOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.el)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 1,
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -1,
          },
        ]),
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.overlayRef.detach());
  }

  /**
   * Attaches the dropdown component to the overlay.
   */
  private attachDropdownToOverlay() {
    const dropdownPortal = new ComponentPortal(
      ContactsDropDownComponent,
      this.viewContainerRef,
    );
    this.componentRef = this.overlayRef.attach(dropdownPortal);
    this.componentRef.instance.contactSelected
      .pipe(take(1))
      .subscribe((contact: Contact) => {
        this.contactSelected.emit(contact);
        this.overlayRef.detach();
      });
  }
}
