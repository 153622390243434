import {
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription, distinctUntilChanged, filter } from 'rxjs';
import { parsePhoneNumber, ParsedPhoneNumber } from 'awesome-phonenumber';

@Directive({
  selector: '[interfacePhoneValidator]',
})
export class PhoneValidatorDirective implements OnInit, OnDestroy {
  sub!: Subscription;
  @Output() valueChanges = new EventEmitter<string>();

  constructor(@Self() private ngControl: NgControl) {}

  ngOnInit(): void {
    this.sub = this.ngControl.valueChanges
      ?.pipe(
        distinctUntilChanged(),
        filter((v) => !!v),
      )
      .subscribe((value) => {
        const pn = this.phoneNumberValidator(value);
        if (pn.valid) {
          this.ngControl.control?.setValue(pn.number.e164);
          this.valueChanges.emit(pn.number.e164);
        } else {
          this.ngControl?.control?.setErrors({ phoneInvalid: true });
          this.ngControl.control?.markAsTouched();
        }
      }) as Subscription;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  /**
   * The function is a TypeScript implementation of a phone number validator that checks if a given phone
   * number is valid.
   * @param {string} value - The `value` parameter is a string representing a phone number that needs to
   * be validated.
   * @returns an object with a single key-value pair. The key is "phoneInvalid" and the value is always
   * true.
   */
  phoneNumberValidator(value: string): ParsedPhoneNumber {
    return parsePhoneNumber(value, { regionCode: 'US' });
  }
}
