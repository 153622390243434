import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[interfacePreventTyping]',
})
export class PreventTypingDirective {
  @HostListener('keydown', ['$event']) keyDown(event: KeyboardEvent) {
    event.preventDefault();
  }
}
